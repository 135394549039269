<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        A compound is known to consist solely of carbon, hydrogen, and oxygen. Through elemental
        analysis, it was determined that the compound is composed of
        <stemble-latex :content="pctCarbon" />
        carbon and
        <stemble-latex :content="pctHydrogen" />
        hydrogen.
      </p>

      <p class="mb-3">a) What is the empirical formula of this compound?</p>

      <p class="pl-4 mb-3">
        <calculation-input
          v-model="inputs.nCarbonEF"
          prepend-text="$\text{C:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nHydrogenEF"
          prepend-text="$\text{H:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nOxygenEF"
          prepend-text="$\text{O:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />
      </p>

      <p class="pl-4 mb-7">
        <stemble-latex style="font-size: 11px" content="$\textbf{Empirical Formula: }$" />
        <chemical-latex v-if="EFnotation" style="font-size: 11px" :content="EFnotation" />
      </p>

      <p class="mb-3">b) What is the molecular weight of the empirical formula?</p>

      <calculation-input
        v-model="inputs.molWeightEF"
        prepend-text="$\text{Mol. Weight:}$"
        append-text="$\text{g/mol}$"
        class="pl-4 mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) If the molar mass of this compound is
        <stemble-latex :content="molarMass" />
        what is its molecular formula?
      </p>

      <p class="pl-4 mb-3">
        <calculation-input
          v-model="inputs.nCarbonMF"
          prepend-text="$\text{C:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nHydrogenMF"
          prepend-text="$\text{H:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nOxygenMF"
          prepend-text="$\text{O:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />
      </p>

      <p class="pl-4">
        <stemble-latex style="font-size: 11px" content="$\textbf{Molecular Formula: }$" />
        <chemical-latex v-if="MFnotation" style="font-size: 11px" :content="MFnotation" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question260',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        nCarbonEF: null,
        nHydrogenEF: null,
        nOxygenEF: null,
        molWeightEF: null,
        nCarbonMF: null,
        nHydrogenMF: null,
        nOxygenMF: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    }, // 1 = C6H12O6, 2 = C4H8O2, 3 = C4H4O8, 4 = C6H8O4, 5 = C2H2O4, 6 = C6H9O6
    versionData() {
      switch (this.versionNumber.value) {
        case 1:
          // 1 = C6H12O6
          return {
            molarMass: '$\\text{180.16 g/mol,}$',
            pctCarbon: '$\\text{40.00\\%}$',
            pctHydrogen: '$\\text{6.71\\%}$',
          };
        case 2:
          // 2 = C4H8O2
          return {
            molarMass: '$\\text{88.11 g/mol,}$',
            pctCarbon: '$\\text{54.53\\%}$',
            pctHydrogen: '$\\text{9.15\\%}$',
          };
        case 3:
          // 3 = C4H4O8
          return {
            molarMass: '$\\text{180.07 g/mol,}$',
            pctCarbon: '$\\text{26.68\\%}$',
            pctHydrogen: '$\\text{2.24\\%}$',
          };
        case 4:
          // 4 = C6H8O4
          return {
            molarMass: '$\\text{144.13 g/mol,}$',
            pctCarbon: '$\\text{50.00\\%}$',
            pctHydrogen: '$\\text{5.59\\%}$',
          };
        case 5:
          // 5 = C2H2O4
          return {
            molarMass: '$\\text{90.04 g/mol,}$',
            pctCarbon: '$\\text{26.68\\%}$',
            pctHydrogen: '$\\text{2.24\\%}$',
          };
        case 6:
          // 6 = C6H9O6
          return {
            molarMass: '$\\text{177.13 g/mol,}$',
            pctCarbon: '$\\text{40.68\\%}$',
            pctHydrogen: '$\\text{5.12\\%}$',
          };
        default:
          return {
            molarMass: null,
            pctCarbon: null,
            pctHydrogen: null,
          };
      }
    },
    molarMass() {
      return this.versionData.molarMass;
    },
    pctCarbon() {
      return this.versionData.pctCarbon;
    },
    pctHydrogen() {
      return this.versionData.pctHydrogen;
    },
    numCEF() {
      return this.buildAtom('C', this.inputs.nCarbonEF);
    },
    numHEF() {
      return this.buildAtom('H', this.inputs.nHydrogenEF);
    },
    numOEF() {
      return this.buildAtom('O', this.inputs.nOxygenEF);
    },
    EFnotation() {
      return `${this.numCEF}${this.numHEF}${this.numOEF}`;
    },
    numCMF() {
      return this.buildAtom('C', this.inputs.nCarbonMF);
    },
    numHMF() {
      return this.buildAtom('H', this.inputs.nHydrogenMF);
    },
    numOMF() {
      return this.buildAtom('O', this.inputs.nOxygenMF);
    },
    MFnotation() {
      return `${this.numCMF}${this.numHMF}${this.numOMF}`;
    },
  },
  methods: {
    /**
     * @param {string} atomType
     * @param {string} number
     * @returns {string}
     */
    buildAtom(atomType, number) {
      if (number) {
        if (number === '1') {
          return atomType;
        } else {
          return atomType.concat(number);
        }
      }
      return `${atomType}_0`;
    },
  },
};
</script>
